<template>
    <el-card v-loading="loading">
        <div slot="header" class="clearfix">
            <span><i class="el-icon-star-off" :style="{ marginRight: '5px', color: '#0027ff' }"></i>需要我进行的审批</span>
            <el-link :underline="false" type="danger">共 {{ table.length }} 项</el-link>
        </div>
        
        <vxe-table :stripe="false" border="none" :show-header="false" :data="table">

            <vxe-column width="100">
                <template #default="{ row }">
                    <span v-if="row.model == 'contract'">CRM - 合同</span>
                    <span v-if="row.model == 'contract-pay-invoice'">CRM - 开票申请</span>
                    <span v-if="row.model == 'ims-product-order'">IMS - 采购订单</span>
                    <span v-if="row.model == 'ims-pay'">IMS - 采购付款</span>
                    <span v-else>{{ row.model }}</span>
                </template>
            </vxe-column>

            <vxe-column>
                <template #default="{ row }">
                    <el-span type="primary" @onClick="$refs.crmContract.Open(row.model_data.id)" v-if="row.model == 'contract'" :title="row.model_data?.number + ' - ' + row.model_data?.name" />
                    <el-span type="primary" @onClick="$refs.imsPayInvoice.Open(row.model_data.id)" v-if="row.model == 'contract-pay-invoice'" :title="row.model_data?.invoice_content" />
                    <el-span type="primary" @onClick="$refs.imsOrder.Open(row.model_data.id)" v-if="row.model == 'ims-product-order'" :title="row.model_data?.number" />
                    <el-span type="primary" @onClick="$refs.imsPay.Open(row.model_data.id)" v-if="row.model == 'contract'" :title="row.model_data?.number" />
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 组件 -->
        <crm-contract-drawer ref="crmContract" @onChange="TableSearch" />
        <contract-pay-invoice-details ref="imsPayInvoice" @onChange="TableSearch" />
        <ims-order-drawer ref="imsOrder" @onChange="TableSearch" />
        <ims-pay-drawer ref="imsPay" @onChange="TableSearch" />

    </el-card>
</template>

<script>
export default {
    data () {
        return {
            loading: false,

            table: [],

            post: {
                search: {
                    current_wait_users_id: this.$store.state.user.id
                },
                append: ['model_data']
            }
        }
    },

    mounted() {
        this.TableSearch()
    },

    methods: {
        TableSearch()
        {
            this.loading = true
            this.$http.post('/9api/components-workflow/search-all', this.post).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        }
    }
}
</script>